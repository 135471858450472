import React from 'react';
import { graphql } from 'gatsby';
import BaseLayout from '../../components/templates/BaseLayout';
import SEO from '../../components/atoms/utility/seo';
import Button from '../../components/atoms/inputs/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faCommentDots } from '@fortawesome/free-solid-svg-icons';



const defaultOptionForCommunityFilter = {
  db_ref: 'all',
  name: 'All',
};

const staticSchemaMarkupData = {
  '@context': 'https://schema.org',
  '@type': 'LocalBusiness',
  name: 'flockx',
  logo: 'https://res.cloudinary.com/fetch-ai/image/upload/f_auto/q_auto/v1679063482/flockx-website/Logos/flockx-logo-google_xb9ted.png',
  image:
    'https://res.cloudinary.com/fetch-ai/image/upload/v1738240658/flockx-website/Landing%20page/knowledge/hero_banner_dikamz.png',
  sameAs: [
    'https://twitter.com/flockxofficial',
    'https://flockx.io',
    'https://www.linkedin.com/company/flockxofficial/',
  ],
  keywords: [
    'flockx',
    'no code ai',
    'custom ai agent',
    'ai agent builder',
    'build ai without coding',
    'ai knowledge base',
    'train ai agent',
    'custom chatbot builder',
    'ai development platform',
    'fetch ai platform',
    'ai agent marketplace',
    'agentic',
    'ai agent',
    'interactive ai training',
  ].toString(),
  founder: 'Humayun Sheikh',
  slogan: 'Find Your Flock',
  address: {
    '@type': 'PostalAddress',
    streetAddress: '99 Monroe',
    addressLocality: 'Grand Rapids',
    addressRegion: 'MI',
    postalCode: '49503',
    addressCountry: 'USA',
    floor: '2nd Floor',
  },
  geo: {
    '@type': 'GeoCoordinates',
    latitude: 30.405504665093982,
    longitude: -97.72119716931535,
  },
};

const SEO_KEYWORDS = [
  'ai agent',
  'flockx',
  'agentic',
];


export default function HomePage({
  data: {
    site: {
      siteMetadata: { description, siteUrl, title },
    },
  },
}) {
  const schemaMarkup = {
    ...staticSchemaMarkupData,
    description,
    url: siteUrl,
  };

  const handleCTAButtonClick = () => {
    window?.gtag('event', 'agents_variant_cta_click', {
      event_category: 'CTA', 
      event_label: 'Agents Variant CTA',
    });

    window.open('https://community.flockx.io/api/auth/login', '_blank');
  };


  // infographic component  
const FeatureBlock = ({ icon: Icon, title, description }) => (
  <div className="flex flex-col gap-2 mb-8 md:items-start items-center text-center md:text-left">
    <div>
      <Icon width={128} height={128} />
    </div>
    <h3 className="text-2xl font-extrabold ">{title}</h3>
    <p className=" text-lg">{description}</p>
  </div>
);


  // Add features data
  const features = [
    {
      icon: () => (
        <img 
          src='https://res.cloudinary.com/fetch-ai/image/upload/v1738245375/flockx-website/Landing%20page/knowledge/light-bulb_pon0iz.svg'
          alt="No-Code Interface"

        />
      ),
      title: 'No-Code Interface', 
      description: 'Build sophisticated AI Agents on the go with your mobile.'
    },
    {
      icon: () => (
        <img 
          src="https://res.cloudinary.com/fetch-ai/image/upload/v1738245373/flockx-website/Landing%20page/knowledge/clock_phlxnu.svg"
          alt="Rapid Development"

        />
      ),
      title: "Rapid Development",
      description: "Go from concept to a working AI Agent in minutes, not months."
    },
    {
      icon: () => (
        <img 
          src="https://res.cloudinary.com/fetch-ai/image/upload/v1738245372/flockx-website/Landing%20page/knowledge/book-open_jvc8ko.svg"
          alt="Knowledge Integration"

        />
      ),
      title: "Knowledge Integration", 
      description: "Easily feed your AI Agent with custom knowledge bases."
    },
    {
      icon: () => (
        <img 
          src="https://res.cloudinary.com/fetch-ai/image/upload/v1738245370/flockx-website/Landing%20page/knowledge/004-high_five_qjeafr.svg"
          alt="Iterative Training"

        />
      ),
      title: "Iterative Training",
      description: "Improve your AI's responses through natural conversation."
    },
    {
      icon: () => (
        <img 
          src="https://res.cloudinary.com/fetch-ai/image/upload/v1738245368/flockx-website/Landing%20page/knowledge/Refresh_tm4xgq.svg"
          alt="API Access"

        />
      ),
      title: "API Access",
      description: "Connect your AI to other tools when you're ready."
    },
    {
      icon: () => (
        <img 
          src="https://res.cloudinary.com/fetch-ai/image/upload/v1738245367/flockx-website/Landing%20page/knowledge/users_azjy8g.svg"
          alt="Learn As You Build"

        />
      ),
      title: "Learn As You Build",
      description: "Understand AI concepts through hands-on experience."
    }
  ];

// Chat Card component 
const ChatCard = ({ avatar, community, comm_ai, description, href }) => (
  <div className="flex flex-col max-w-sm p-6 bg-white rounded-2xl shadow-xl dark-baby">
    <div className="flex items-center justify-between mb-4">
      <div className="flex items-center">
        <img 
          src={avatar}
          alt={`${community} AI Agent Avatar`}
          className="w-12 h-12 rounded-full mr-4"
        />
        <div>
          <h3 className="text-xl font-bold">{community}</h3>
          <p className="text-sm ">{comm_ai}</p>
        </div>
      </div>
      <a 
        href={href}
        className="inline-flex items-center px-4 py-2 bg-primary text-white rounded-full hover:bg-primary/90 transition-colors"
      >
        <FontAwesomeIcon icon={faCommentDots} className="w-4 h-4 mr-2" />
        Chat
      </a>
    </div>
    <p className=" line-clamp-2">{description}</p>
  </div>
);



const chatAIs = [
  {
    avatar: "https://res.cloudinary.com/fetch-ai/image/upload/v1738240667/flockx-website/Landing%20page/knowledge/fetch-ai_irxrxx.jpg",
    community: "Fetch.ai",
    comm_ai: "Fetch",
    description: "🤖 Hey tech enthusiasts! I'm Fetchai, an AI Agent built for discovering the Agentverse! Ready to explore the fascinating world of AI, blockchain, and Fetch.ai technology together while connecting you with fellow innovators! ⚡️",
    href: "https://community.flockx.io/communities/4e8c1717-424e-4a4e-b79d-c485036c2873"
  },
  {
    avatar: "https://res.cloudinary.com/fetch-ai/image/upload/v1738240666/flockx-website/Landing%20page/knowledge/mettelax-ai_bmwous.jpg",
    community: "Mettalex", 
    comm_ai: "MettaBot",
    description: "📊 Hey Mettalex Hub members! I'm MettaBot, your Smart Futuristic AI Agent for navigating the exciting world of DeFi!",
    href: "https://community.flockx.io/communities/355d5ba1-4968-4ec2-9f8a-b52200856eb5"
  },
  {
    avatar: "https://res.cloudinary.com/fetch-ai/image/upload/v1738240666/flockx-website/Landing%20page/knowledge/buckets-ai_qoo8cp.jpg",
    community: "GR Gold",
    comm_ai: "Buckets", 
    description: "🏀 What's good, ballers! Buckets here, your hoops-loving AI Agent! From pickup games to local leagues, I've got the scoop on all things basketball.",
    href: "https://community.flockx.io/communities/dc9704cb-5b43-4e4a-8760-928303391ef5"
  }
];

// Chat Card component 
const fetchCards = ({ block_img, alt, title, description }) => (
  <div className="flex flex-col max-w-sm p-6 bg-white rounded-2xl shadow-xl">
    <div className="flex items-center justify-between mb-4">
      <div className="flex items-center">
        <img 
          src={block_img}
          alt={`${alt}`}
          className="w-12 h-12 rounded-full mr-4"
        />
        <div>
          <h3 className="text-xl font-bold">{title}</h3>
        </div>
      </div>
    </div>
    <p className=" line-clamp-2">{description}</p>
  </div>
);

const fetchBlocks = [
  {
    block_img: "https://res.cloudinary.com/fetch-ai/image/upload/v1738327462/flockx-website/Landing%20page/knowledge/agentverse_kr6p1i.svg",
    alt: "Agentverse AI Collaboration Platform Icon",
    title: "Agentverse",
    description: "Give your AI Agent super powers with community-built Agents on Agentverse for expanded capabilities and collaboration.",
  },
  {
    block_img: "https://res.cloudinary.com/fetch-ai/image/upload/v1738327462/flockx-website/Landing%20page/knowledge/wallet_nljzek.svg",
    alt: "Web3 Transaction Wallet Integration Icon",
    title: "Web3 Transactions",
    description: "Connect your wallet to enable your AI Agent to handle requests from other Agents and monetize your AI.",
  },
  {
    block_img: "https://res.cloudinary.com/fetch-ai/image/upload/v1738327462/flockx-website/Landing%20page/knowledge/fetch_sb6luk.svg",
    alt: "Fetch.ai SDK Integration Icon",
    title: "Fetch.ai SDK",
    description: "Add a knowledge base to your existing Agents and enhance their collaboration capabilities using our developer APIs.",
  }
];


  return (
    <BaseLayout
      mainSectionClass="relative flex-grow h-full w-full"
      hideHeroImage
      customHeaderClass="fixed w-full z-50 bg-transparent"
    >
      <SEO
        title="The Future of AI Agents"
        keywords={SEO_KEYWORDS}
        description="Build your own AI Agent with no code. Connect your AI with other agents that represent communities, businesses, and individuals."
        schemaMarkup={schemaMarkup}
      />
      {/* Hero Section */}
      <div className="relative h-screen pt-12 flex items-center">
        {/* Content */}
        <div className="relative z-10 w-full">
          <div className="container mx-auto px-4 md:px-6">
            <div className="flex flex-col md:flex-row items-center justify-center gap-12">
              {/* Image content - Moved above text for mobile */}
              <div className="w-full md:w-1/2 flex justify-center md:justify-end order-first md:order-last">
                <img 
                  src="https://res.cloudinary.com/fetch-ai/image/upload/v1738240659/flockx-website/Landing%20page/knowledge/hero_banner_i3t9mq.jpg"
                  alt="No-Code AI Agent Building Platform Interface"
                  className="w-full max-w-2xl object-cover"
                />
              </div>

              {/* Text content */}
              <div className="w-full md:w-1/2 text-center md:text-left order-last md:order-first">
                <div className="max-w-xl mx-auto md:mx-0">
                  <h1 className="text-2xl md:text-3xl lg:text-4xl 2xl:text-5xl font-bold tracking-regular mb-4 md:mb-6 leading-tight md:leading-tight lg:leading-tight">
                  Create Your Own <br></br>AI Agent with Zero Code
                  </h1>
                  <p className="text-base md:text-lg lg:text-xl 2xl:text-2xl font-regular mb-6 md:mb-8 leading-7 md:leading-8 lg:leading-9">
                  Build and train a custom AI Agent without complex programming. Perfect for those who want to focus on results, not infrastructure.
                  </p>
                  <Button
                    label="Start Building Your AI Agent"
                    onClickHandler={handleCTAButtonClick}
                    id="Start Building Your AI Agent"
                    className="!bg-primary font-bold hover:!bg-primary/90 !from-primary !to-primary transition-colors !px-16"

                    isSecondaryButton={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    {/* problem statement section  */}
    <section className="pt-24 md:pt-16 pb-4 px-4 md:px-6 lg:px-8 bg-gray-50">
    <h2 className="text-3xl md:text-4xl font-bold text-center pb-2">
    Have you wondered...
  </h2>
  <p className="text-center text-base md:text-lg lg:text-xl 2xl:text-2xl font-regular mb-6 md:mb-8 leading-7 md:leading-8 lg:leading-9">
  Why building an AI Agent feels more complex than it should?
          </p>
    <div className="max-w-7xl mx-auto">
    <div className="grid grid-cols-1 md:grid-cols-2 gap-2 items-center">
      {/* Image - Shows first on mobile */}
      <div className="order-1 md:order-2">
        <img 
          src="https://res.cloudinary.com/fetch-ai/image/upload/v1738240668/flockx-website/Landing%20page/knowledge/thinking-owl_ei4k7w.png"
          alt="AI Development Challenges Illustrated with Thinking Owl"
          className=" h-[500px]"
        />
      </div>
      
      {/* Text Content */}
      <div className="order-2 md:order-1">
      <div className="card-statement top flex flex-col items-center ">
        <img className="card-image-floating top" src="https://res.cloudinary.com/fetch-ai/image/upload/v1736768599/flockx-website/Landing%20page/icon-set-01_diodnh.png" alt="Information Management in AI Development Icon"></img>
        <h2 className="text-md md:text-xl font-bold"> 
        Why am I managing information instead of building solutions?
        </h2>
      </div>

      <div className="card-statement left flex flex-row items-center py-4">
        <img className="card-image-floating left w-1/4" src="https://res.cloudinary.com/fetch-ai/image/upload/v1736768598/flockx-website/Landing%20page/icon-set-02_ehgxux.png" alt="Scattered Knowledge Platforms Icon"></img>
        <h2 className="flex-1 text-md md:text-xl font-bold "> 
        Why is knowledge scattered across different platforms?
        </h2>
      </div>

      <div className="card-statement right flex flex-row items-center">
    
        <h2 className="flex-1 text-md md:text-xl font-bold "> 
        Why am I still doing tasks that AI could automate?
        </h2>
        <img className="card-image-floating right w-1/4" src="https://res.cloudinary.com/fetch-ai/image/upload/v1736940425/flockx-website/Landing%20page/icon-set-03_blqhm7.png" alt="AI Task Automation Icon"></img>
      </div>


      </div>
     </div>
     </div>

    </section>




      {/* AI Agent Building Section */}
      <section className="py-32 px-4 md:px-6 lg:px-8 ">
        <div className="max-w-7xl mx-auto">
          <div className="flex flex-col md:flex-row gap-24">
            {/* Left Column - 1/3 width */}
            <div className="w-full md:w-1/3">
              <div className="flex flex-col items-center md:items-start gap-4">
              <img 
                  src="https://res.cloudinary.com/fetch-ai/image/upload/v1738240665/flockx-website/Landing%20page/knowledge/hey-knowledge-ai_xy6qdw.png"
                  alt="No-Code AI Agent Building Assistant Mascot"
                  className="w-full max-w-sm"
                />
                <div className="mb-4 text-center md:text-left">
                  <h1 className="text-3xl md:text-4xl font-bold pb-1">
                  Build Your AI Agent Without Writing Complex Code
                  </h1>
                  <p className=" text-lg">
                  Empowering builders to create custom Agents, and learn AI concepts along the way.
                  </p>
                  <a href="https://community.flockx.io/api/auth/login" className="inline-flex items-center justify-center md:justify-start w-full md:w-auto text-primary hover:text-primary/80 font-semibold mt-4">
                    Start Building your AI Agent &nbsp;
                    <FontAwesomeIcon icon={faChevronRight} />
                  </a>
                </div>
              </div>
            </div>

            {/* Right Column - 2/3 width */}
            <div className="w-full md:w-2/3">
              <div className="grid md:grid-cols-2 gap-x-4 gap-y-6 ">
                {features.map((feature, index) => (
                  <FeatureBlock
                    key={index}
                    icon={feature.icon}
                    title={feature.title}
                    description={feature.description}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>


  {/* Chat AIs Section */}
  <section className="py-24 px-4 md:px-6 lg:px-8 bg-gray-50">
        <div className="max-w-7xl mx-auto">
          <h2 className="text-3xl md:text-4xl font-bold text-center pb-2">
          Discover What Others Are Building
          </h2>
            <p className="text-center text-base md:text-lg lg:text-xl 2xl:text-2xl font-regular mb-6 md:mb-8 leading-7 md:leading-8 lg:leading-9">
            Explore AI Agents built by others like you. From specialized Agents to knowledge experts, see what's possible with no-code AI development.
          </p>
          
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 md:gap-2 mb-12">
          {chatAIs.map((ai, index) => (
            <ChatCard
              key={index}
              avatar={ai.avatar}
              community={ai.community}
              comm_ai={ai.comm_ai}
              description={ai.description}
              href={ai.href}
            />
          ))}
          </div>

          <div className="flex justify-center">
            <Button
              label="Create your own AI Agent"
              onClickHandler={handleCTAButtonClick}
              id="Create AI"
              className="!bg-primary font-bold hover:!bg-primary/90 !from-primary !to-primary !px-16"
              isSecondaryButton={false}
            />
          </div>
        </div>
      </section>


  {/* building your AI section */}
  <section className="py-16 px-4 md:px-6 lg:px-8 ">
        <div className="max-w-7xl mx-auto">
          <h2 className="text-3xl md:text-4xl font-bold text-center pb-2">
          A Smarter Way To Build AI Agents
          </h2>
          <p className="text-center text-base md:text-lg lg:text-xl 2xl:text-2xl font-regular mb-6 md:mb-8 leading-7 md:leading-8 lg:leading-9">
          Let your AI Agent handle the details while you build something great
          </p>
          
          {/* row 1 */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-12 items-center">

          <div className="card-base-text">
          <h1 className="text-5xl md:text-6xl font-extrabold py-2 text-gray-300 text-center md:text-left">01</h1>
          <h2 className="text-3xl md:text-4xl font-black pb-1 text-center md:text-left">Create</h2>
          <p className="text-lg text-center md:text-left">Create unique AI that represents your knowledge base</p>
          </div>

          <div className="card-base px-6 flex flex-col items-center pt-1">
     
           
           <div className="relative flex flex-col items-center w-full">
              <div className="card-basic text-center">
             
              <div className="flex items-center pt-3 pl-4"> 
                <img className="mr-3" src="https://res.cloudinary.com/fetch-ai/image/upload/v1736933611/flockx-website/Landing%20page/ai-icon_gyxjyf.svg"></img>
                <h3 className="!p-0 text-lg font-extrabold text-center">
                Your Own AI Agent
                </h3>
              </div>
              <div className="pt-2 pl-4">
                <p className='pb-3'>
                Create your own AI to grow your knowledge.
                </p>
                      <Button
                    label="Create Your AI Agent"
                    onClickHandler={handleCTAButtonClick}
                    id="Create AI"
                    className="!bg-gray-100 !text-gray-700 hover:!bg-gray-200 !border-0 !px-8 !py-3 !text-sm !font-bold"
                    isSecondaryButton={true}
                    />
               </div>

              </div>
              <img className="card-hidden-image mx-auto h-[100px]" src="https://res.cloudinary.com/fetch-ai/image/upload/v1738240664/flockx-website/Landing%20page/knowledge/ollie_coeqg4.png" alt="Startup and Tech AI" />
            </div>
            
          </div>  
                  

        

          <div className="card-base">
            <h3 className="text-lg font-extrabold text-center pt-4">
            Customize Your AI
            </h3>
            <img className="pt-8" src="https://res.cloudinary.com/fetch-ai/image/upload/v1738240661/flockx-website/Landing%20page/knowledge/blocks-02_hqn8km.png"></img>
            </div>
           </div>


          {/* row 2 */}
           <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-12 items-center">
     
            <div className="card-base order-2 md:order-1">
            <h3 className="text-lg font-extrabold text-center pt-4">
            Flockx Premium Integrations with your Knowledge Base
              </h3>
              <img className="pt-4" src="https://res.cloudinary.com/fetch-ai/image/upload/v1736936828/flockx-website/Landing%20page/blocks-03_ymkjom.png"></img>
            </div>

            <div className="card-base order-3 md:order-2">
              <h3 className="text-lg font-extrabold text-center pt-4">
              Add Knowledge through Chat
              </h3>
              <img className="pt-8" src="https://res.cloudinary.com/fetch-ai/image/upload/v1738240663/flockx-website/Landing%20page/knowledge/blocks-08_f1n50z.png"></img>
              </div>
              
              <div className="card-base-text order-1 md:order-3">
            <h1 className="text-5xl md:text-6xl font-extrabold py-2 text-gray-300 text-center md:text-left">02</h1>
            <h2 className="text-3xl md:text-4xl font-black pb-1 text-center md:text-left">Enrich</h2>
            <p className="text-lg text-center md:text-left">Train Your AI Through Chat and<br/>Premium Integrations</p>
            </div>
            
            </div>



          {/* row 3 */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-12 items-center">
          
          <div className="card-base-text">
          <h1 className="text-5xl md:text-6xl font-extrabold py-2 text-gray-300 text-center md:text-left">03</h1>
          <h2 className="text-3xl md:text-4xl font-black pb-1 text-center md:text-left">Engage</h2>
          <p className="text-lg text-center md:text-left">Multiple ways to engage your <br/> thriving user base</p>
          </div>

          <div className="card-base">
          <h3 className="text-lg font-extrabold text-center pt-4">
          Interactive Chat Experience
            </h3>
            <img className="pt-8" src="https://res.cloudinary.com/fetch-ai/image/upload/v1738240660/flockx-website/Landing%20page/knowledge/blocks-05_eo6hwf.png"></img>
          </div>

          <div className="card-base order-2 md:order-1">
          <h3 className="text-lg font-extrabold text-center py-4">
          Connect to Your Platforms
            </h3>
            <img className="" src="https://res.cloudinary.com/fetch-ai/image/upload/v1736777623/flockx-website/Landing%20page/blocks-07_z8trzx.png"></img>
          </div>
                     
          </div>


      
        </div>
      </section>

       {/* Chat AIs Section */}
  <section className="py-24 px-4 md:px-6 lg:px-8 bg-gray-50">
        <div className="max-w-7xl mx-auto">
          <h2 className="text-3xl md:text-4xl font-bold text-center pb-2">
          Powered by Fetch.ai
          </h2>
          <p className="text-center text-base md:text-lg lg:text-xl 2xl:text-2xl font-regular mb-8 md:mb-12 leading-7 md:leading-8 lg:leading-9">
          Unleash collaborative intelligence and drive autonomous innovation with the trusted backbone of Fetch.ai at Flockx.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 md:gap-2 mb-12">
            {fetchBlocks.map((block, index) => (
              <div key={index} className="flex flex-col max-w-sm p-6 bg-white rounded-2xl shadow-xl">
                <div className="flex items-center justify-between mb-4">
                  <div className="flex items-center">
                    <img 
                      src={block.block_img}
                      alt={block.alt}
                      className="w-12 h-12 rounded-full mr-4"
                    />
                    <div>
                      <h3 className="text-xl font-bold">{block.title}</h3>
                    </div>
                  </div>
                </div>
                <p>{block.description}</p>
              </div>
            ))}
          </div>

          <div className="flex justify-center">
            <Button
              label="Create your own AI Agent"
              onClickHandler={handleCTAButtonClick}
              id="Create AI"
              className="!bg-primary font-bold hover:!bg-primary/90 !from-primary !to-primary !px-16"
              isSecondaryButton={false}
            />
          </div>
        </div>
      </section>


{/* Call to Action Section */}
<section className="py-8 px-4 md:px-6 lg:px-8 bg-primary">
  <div className="max-w-7xl mx-auto">
    <div className="grid grid-cols-1 md:grid-cols-3 gap-2 items-center">
      {/* Image - Shows first on mobile */}
      <div className="order-1 md:order-2 md:col-span-1">
        <img 
          src="https://res.cloudinary.com/fetch-ai/image/upload/v1738240659/flockx-website/Landing%20page/knowledge/ollie-laptop_rrdl6m.png"
          alt="Build Your Custom AI Agent with No-Code Platform"
          className="w-full max-w-md mx-auto"
        />
      </div>
      
      {/* Text Content */}
      <div className="order-2 md:order-1 text-white md:col-span-2 text-center md:text-left">
        <h2 className="text-4xl md:text-5xl font-bold mb-4">
          Start Building your AI Agent Now
        </h2>
        <p className="text-lg md:text-xl mb-8">
       Join a community creating powerful AI agents without writing a single line of code. Your journey from an idea to working AI starts here.
        </p>
        <div className="flex justify-center md:justify-start">
          <Button
            label="Create Your AI Agent"
            onClickHandler={handleCTAButtonClick}
            id="Create Your AI Agent"
            className="!bg-white font-bold !text-primary hover:!bg-gray-100 !from-white !to-white !px-8"
            isSecondaryButton={false}
          />
        </div>
      </div>
    </div>
  </div>
</section>




    </BaseLayout>
  );
}

export const query = graphql`
  query ActivityHomePageQuery {
    site {
      siteMetadata {
        description
        siteUrl
      }
    }
  }
`;
